import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";

// Components
import SEO from "../../components/seo/seo";
import SecondaryLanding from "../../components/layouts/secondary-landing";
import HeroChevron from "../../components/hero/hero-chevron";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../components/contact-info/contact-info";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import VimeoVideoFrame from "../../components/custom-widgets/vimeo-video-frame";
import DebitCardsOpenAccount from "../../components/sign-up/debit-cards-open-account";
import BestBanksDefault from "../../components/best-banks/best-banks-default";
import FeaturesSection from "../../components/rewards-checking/features-section";
import ServiceStatesOpenAccountSection from "../../components/service-states/service-states-open-account-section";
import StartSavingsSection from "../../components/start-savings-section/start-savings-section";

const SpendingAccount = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/spending-account/hero-spending-account-061124-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/personal-banking/spending-account/hero-spending-account-061124-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/personal-banking/spending-account/hero-spending-account-061124-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/personal-banking/spending-account/hero-spending-account-061124-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/personal-banking/spending-account/hero-spending-account-061124-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/personal-banking/spending-account/hero-spending-account-061124-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/spending-account/hero-spending-account-061124-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const seoData = {
    title: "Spending Account with No Fees",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "Spending Account with No Fees"
      },
      {
        name: "description",
        content:
          "Discover better banking with WaFd Bank. Learn about our spending account, an online bank account with free direct deposit that allows you to bank with ease."
      },
      {
        property: "og:title",
        content: "Spending Account with No Fees"
      },
      {
        property: "og:description",
        content:
          "Discover better banking with WaFd Bank. Learn about our spending account, an online bank account with free direct deposit that allows you to bank with ease."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/spending-account"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-spending-account-061124.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "spending-account-hero",
    ...getHeroImgVariables(imgData),
    altText: "Friends walking down the street with bikes.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "*Free Spending Account&mdash;All the Right Stuff for Free!"
          }
        },
        {
          id: 2,
          button: {
            id: "spending-account-cta",
            text: "Open an Account",
            url: "/open-an-account",
            class: "btn-white",
            containerClass: "mb-3"
          }
        },
        {
          id: 3,
          tertiaryText: {
            class: "text-white",
            text: "*Nonsufficient funds charge may apply."
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      active: true,
      title: "Spending Account"
    }
  ];

  const debitCardData = {
    sectionId: "spending-account-debit-cards-section",
    titleText: "Let's Open Your Free Spending Account Today",
    depositText: "Only $25 initial deposit to open."
  };

  const freeCheckingVideoData = {
    vimeoId: "733695543",
    allowFeatures: "fullscreen",
    title: "Free Checking at WaFd Bank Offers No Minimum Balance or Monthly Fees | WaFd Bank",
    class: "m-auto iframe w-100 border-radius-12"
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <HeroChevron {...heroChevronData} />
      <SEO {...seoData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Spending Account</h1>
        <div className="row">
          <div className="col-md-6 mb-4 mb-md-0">
            <VimeoVideoFrame {...freeCheckingVideoData} />
          </div>
          <div className="col-md-6">
            <h2 className="text-success">A secure digital checking account backed by a Best Bank</h2>
            <p>
              Are you tired of paying monthly fees for your checking account? Look no further! Our Spending Checking
              Account, powered by WaFd Bank Free Checking, offers a hassle-free banking experience with{" "}
              <span className="text-success font-weight-bold">no minimum balance requirements</span> and{" "}
              <span className="text-success font-weight-bold">no monthly maintenance fees</span>*. Whether you're
              opening your first account or{" "}
              <Link to="/blog/banking-101/how-to-switch-banks" id="how-to-switch-banks-blog-link">
                looking to switch
              </Link>{" "}
              to a more cost-effective option, our Spending Checking Account is designed to meet your needs.
            </p>
            <div className="row align-items-center mb-3">
              <div className="col-sm-auto col-md-12 col-lg-auto mb-3 mb-sm-0 mb-md-3 mb-lg-0">
                <Link
                  to="/personal-banking/checking-account#checking-overview-section"
                  id="checking-compare-accounts-link"
                  className="btn btn-link w-100 w-sm-auto"
                >
                  Compare Checking Accounts
                </Link>
              </div>
              <div className="col-sm col-md col-lg">
                <Link to="/open-an-account" id="open-account-link" className="btn btn-primary w-100 w-sm-auto">
                  Open an Account
                </Link>
              </div>
            </div>
            <p id="free-checking-benefits-disclaimer" className="mb-0 text-muted text-sm">
              *Nonsufficient Funds Charge may apply.
            </p>
          </div>
        </div>
      </section>
      <FeaturesSection />
      <DebitCardsOpenAccount {...debitCardData} />
      <StartSavingsSection />
      <ServiceStatesOpenAccountSection />
      <ContactInfo bgClass="bg-white" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default SpendingAccount;
